import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageTestimonialsHeroSection from "../components/sections/page-testimonials-hero-section"
import TestimonialsSection from "../components/sections/testimonials-section"
import BenefitsSection from "../components/sections/benefits-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import CtaSection from "../components/sections/cta-section"

const TestimonialsPage = () => (
  <Layout>
    <Seo
      title="Testimonials"
      description="Don’t take our word for it. Here’s what our clients are saying about the IT Proactive Team."
      noIndex
    />

    <PageTestimonialsHeroSection />
    <TestimonialsSection />
    <BenefitsSection />
    <CapabilitiesSection />
    <QuickQuoteSection />
    <CtaSection />
  </Layout>
)

export default TestimonialsPage
